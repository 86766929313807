import React from 'react';
import { Button, Label, Paragraph } from '../../../components/atomics';
import { URL_APP_FINANCES_WEB } from '../../../constants';
import Page from '../../../components/Pages/Page';

const PlataformaWeb = () => {
  return (
    <Page  title="Flynow - Finanças" description="Plataforma Web do Aplicativo Flynow Finanças Pessoais" 
         logo={require(`../../../assets/images/app/finances/logo.png`)}
      >
      <br/>
      <h1>Flynow Finanças Pessoais Web</h1>
      <Paragraph>Com a Plataforma Web é possível gerenciar suas finanças pessoais também pelo navegador web</Paragraph>
      <Label>Como acessar a Plataforma Web?</Label>
      <Paragraph>
        Para acessar a plataforma web, acesse o <a href={URL_APP_FINANCES_WEB} target="_blank" rel="noopener noreferrer">link</a>. Em seguida, faça o login com sua conta mesma conta do app e utilize à vontade!
      </Paragraph>
      <Paragraph>
        <Button  href={URL_APP_FINANCES_WEB} target="_blank" rel="noopener noreferrer" style={{width:300, color:'white',alignItems:'center',justifyContent:'center', display:'flex',backgroundColor: '#29CD83' 
        }}> Acessar Plataforma Web</Button>
      </Paragraph>
      <br/>
      <img  alt="plataforma web" src={require('../../../assets/images/finances-web.png')} style={{width:'100%'}} />
      <br/>
    </Page>
  )
}

export default PlataformaWeb;